@media screen and (min-width: 449px) {
    .videoContainer {
        width: 100%;
        height: 100%;
        min-height: 450px;
        .video {
            width: 100% !important;
            height: 100%;
            min-height: 450px;
            max-height: 650px;
        }
        .btns {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            margin: 10px 0px;
            .notComplete {
                cursor: no-drop;
            }

            .btn {
                text-align: center;
                padding: 5px 20px !important;
                height: 40px;
                cursor: pointer;
                font-size: 14px;
                font-family: inherit;
            }
        }
    }
}
@media screen and (max-width: 450px) {
    .videoContainer {
        width: 100%;
        height: auto;
        .video {
            width: 100% !important;
            height: 100%;
            max-height: 250px;
        }
        .btns {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            margin: 10px 0px;
            .notComplete {
                cursor: no-drop;
            }

            .btn {
                text-align: center;
                padding: 5px 20px !important;
                height: 40px;
            }
        }
    }
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


// @media screen and (min-width: 449px) {
//     .videoContainer {
//         width: 100%;
//         height: 100%;
//         background-color: #232c39;
//         .video {
//             width: 100% !important;
//             height: calc(100% - 130px) !important;
//             object-fit: cover;
//         }
// .btns {
//     position: relative;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 50px;
//     margin: 10px 0px;
//     .notComplete {
//         cursor: no-drop;
//     }

//     .btn {
//         text-align: center;
//         padding: 5px 20px !important;
//         height: 40px;
//     }
// }
//     }
// }
// @media screen and (max-width: 450px) {
//     .videoContainer {
//         width: 100%;
//         height: 100%;
//         background-color: #232c39;
//         .video {
//             width: 100% !important;
//             height: calc(100% - 100px) !important;
//             object-fit: cover;
//         }
//         .btns {
//             position: relative;
//             height: 50px;
//             width: 100%;
//             display: flex;
//             align-items: center;
//             margin: 10px 0px;
//             justify-content: center;
//             .notComplete {
//                 cursor: no-drop;
//             }
//             .btn {
//                 height: 40px;
//                 text-align: center;
//                 padding: 5px 20px !important;
//             }
//             .btn {
//                 text-align: center;
//                 padding: 5px 20px !important;
//             }
//         }
//     }
// }
