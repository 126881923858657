/* TODO refactor using scss , based in https://www.w3schools.com/howto/howto_css_tooltip.asp*/

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;

  bottom: 100%;
  left: 50%;
  margin-left: -60px;


  position: absolute;
  z-index: 1;

  opacity: 0;
  transition: opacity 1s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}