.errorContainer {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #232c39;
    z-index: -1;
  }
  .content {
    width: 100%;
    max-width: 500px;
    text-align: center;
    z-index: 1;
    position: relative;
    font-size: 18px;
    color: #fff;
    padding: 80px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      background: #000;
      opacity: 0.5;
      border-radius: 10px;
      z-index: -1;
    }
    a {
      color: #8eb53e;
    }
    img {
      width: 100px;
      margin: 10px 0px;
    }
  }
}
