
.App { 
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.ant-result-title {
  color: white !important;
}

.contentHtml {
  h1, h3 {
    color: white !important;
  }
}