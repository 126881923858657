.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 10px;

  width: 200px;
  height: 40px;
  border: 0;
  border-radius: 10px;
  background:#8eb53e;

  &.primary {
    background-color: #8eb53e !important;
  }

  &:disabled {
    background-color: #ccc !important;
    cursor: not-allowed !important;
    outline: none;
  }
  
  &:focus {
    background-color: #aad652 !important;   
    outline: 1px #8eb53e;
  }
}
