.container {
    position: relative;
    height: 100vh;
    z-index: 1;
    .bg {
        background: #232c39;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        z-index: -1;
        left: 0px;
    }
    .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 520px;
        width: 100%;
        line-height: 1.4;
        text-align: center;

        img {
            width: 100px;
            margin: 10px 0px;
        }
        .notfound-404 {
            position: relative;
            height: 200px; 
            h1 {
                font-family: "Montserrat", sans-serif;
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                font-size: 150px;
                font-weight: 600;
                margin: 0px;
                color: #fff;
                text-transform: uppercase;
                letter-spacing: -15px;
                margin-left: -20px;
            }
            h1 > span {
                text-shadow: -8px 0px 0px #aaa;
            }
            h3 {
                font-family: "Cabin", sans-serif;
                position: relative;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                color: #232c39;
                margin: 20px 0px;
                letter-spacing: 3px;
                padding-left: 6px;
            }
        }
        h2 {
            font-family: "Cabin", sans-serif;
            font-size: 20px;
            font-weight: 400;
            text-transform: uppercase;
            color: #232c39;
            margin-top: 0px;
            margin-bottom: 25px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .container {
        .notfound {
            .notfound-404 { 
                h1 {
                    font-size: 200px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .container {
        .notfound {
            .notfound-404 {
                height: 162px;
                h1 {
                    font-size: 162px !important;
                    line-height: 162px;
                }
            }
            h2 {
                font-size: 16px;
            }
        }
    }
}
.boton {
    width: 100%;
    height: 40px; 
    .btn {
        text-align: center;
        z-index: 100;
        width: 100%; 
        background: #232c39;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: #fff;
        font-size: larger;
        cursor: pointer;
    }
}
