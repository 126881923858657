@media screen and (min-width: 449px) {
    .questionContainer {
        background-color: #232c39;
        color: #dce1e3;
        width: 100%;
        overflow: auto;
        height: 100% !important;
        display: flex;
        align-items: center;
        margin: 20px 0px;
        justify-content: center;
        .questionList {
            padding: 10px;
            border-radius: 7px;
            background-color: #232c39;
            width: 100%;
            max-width: 720px;
            display: flex;
            flex-direction: column;
            .title {
                font-size: 1.2rem;
                display: flex;
                align-items: center;
                color: #fff;
            }
            .questionItem {
                display: flex;
                align-items: center;
                flex-direction: row;
                .labelName{
                    color: #bbb;
                    display: flex;
                    margin: 10px 0px;
                    align-items: center;
                    padding: 10px;
                    justify-content: center;
                    max-width: 25px;
                }
                .question {
                    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.14);
                    color: #666666;
                    cursor: pointer;
                    border-radius: 5px;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -o-user-select: none;
                    user-select: none;
                    margin: 10px 0px;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 5px;
                    margin-right: 15px;
                    transition: all 0.2s ease-in-out;
                    color: #666666;
                    padding: 10px;
                    background-color: #fff;
                }
                .active {
                    background: #8eb53e !important;
                    color: #fff;
                    transition: all 0.2s ease-in-out;
                }
                .activeLabelName{
                    color:#fff;
                    display: flex;
                    margin: 10px 0px;
                    align-items: center;
                    padding: 10px;
                    justify-content: center;
                    max-width: 25px;
                    font-weight: bold;
                }
            }
            .btns {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                .btn {
                    width: 200px;
                    height: 40px;
                    border-radius: 10px;
                    background: #8eb53e;
                    color: #fff;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: inherit;
                }
            }
        }
        .successComponent {
            .btns {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                .btn {
                    width: 200px;
                    height: 40px;
                    border-radius: 10px;
                    background: #8eb53e !important;
                    color: #fff !important;
                    cursor: pointer;
                    font-size: 14px;
                }
            }
            div {
                color: white;
                background-color: #232c39;
            }
            .ant-result-subtitle {
                color: white !important;
            }
            .ant-result-content {
                background-color: inherit !important;
            }   
        }
        .errorComponent {
            width: 100%;
            height: 100%;
            .btns {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                background-color: #232c39;
                .btn {
                    width: 200px;
                    height: 40px;
                    border-radius: 10px;
                    background: #8eb53e;
                    color: #fff;
                    cursor: pointer;
                }
            }

            div {
                color: white;
                background-color: #232c39;
            }

            .result {
                .ant-result-subtitle {
                    color: white !important;
                }
                .ant-result-content {
                    background-color: inherit !important;
                }
            }
        }
        .videoComponent {
            height: 100% !important;
            width: 100%;
            .videoUI {
                width: 100%;
                height: 100%;
            }
            .btns {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                .btn {
                    width: 200px;
                    height: 55px;
                    border-radius: 10px;
                    background: #8eb53e;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
}
@media screen and (max-width: 450px) {
    .questionContainer {
        background-color: #232c39;
        margin: 10px 0px;
        color: #dce1e3;
        width: 100%;
        overflow: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .questionList {
            width: 100%;
            margin: 10px;
            padding: 10px;
            border-radius: 7px;
            background-color: #232c39;
            max-width: 310px;
            .title {
                color: #fff;
                font-size: 1.2rem;
                display: flex;
                align-items: center;
            }
            .questionItem {
                display: flex;
                align-items: center;
                flex-direction: row;
                .labelName{
                    color: #bbb;
                    display: flex;
                    margin: 10px 0px;
                    align-items: center;
                    padding: 10px;
                    justify-content: center;
                    max-width: 25px;
                }
                .question {
                    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.14);
                    color: #666666;
                    cursor: pointer;
                    border-radius: 5px;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -o-user-select: none;
                    user-select: none;
                    margin: 10px 0px;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 5px;
                    margin-right: 15px;
                    transition: all 0.2s ease-in-out;
                    color: #666666;
                    padding: 10px;
                    background-color: #fff;
                }
                .active {
                    background: #8eb53e !important;
                    color: #fff;
                    transition: all 0.2s ease-in-out;
                }
                .activeLabelName{
                    color:#fff;
                    display: flex;
                    margin: 10px 0px;
                    align-items: center;
                    padding: 10px;
                    justify-content: center;
                    max-width: 25px;
                    font-weight: bold;
                }
            }
            .btns {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                .btn {
                    width: 200px;
                    height: 40px;
                    border-radius: 10px;
                    background: #8eb53e;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
        .successComponent {
            .btns {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                .btn {
                    width: 200px;
                    height: 40px;
                    border-radius: 10px;
                    background: #8eb53e;
                    color: #fff;
                    cursor: pointer;
                }
            }
            .result {
                .ant-result-subtitle {
                    color: white !important;
                }
                .ant-result-content {
                    background-color: inherit !important;
                }
            }
        }
        .errorComponent {
            width: 100%;
            height: 100%;
            .btns {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                .btn {
                    width: 200px;
                    height: 40px;
                    border-radius: 10px;
                    background: #8eb53e;
                    color: #fff;
                    cursor: pointer;
                }
            }
            .result {
                .ant-result-subtitle {
                    color: white !important;
                }
                .ant-result-content {
                    background-color: inherit !important;
                }
            }
        }
        .videoComponent {
            height: 100% !important;
            max-height: 650px !important;
            width: 100%;
            .videoUI {
                width: 100%;
                height: 100%;
            }
            .btns {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                .btn {
                    width: 200px;
                    height: 55px;
                    border-radius: 10px;
                    background: #8eb53e;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
}
