@media screen and (min-width: 449px) {
    .root {
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #232c39;
        .questionComponent,
        .textComponent,
        .videoComponent,
        .textComponent,
        .completeContainer {
            .header {
                flex-direction: column;
                width: 100%;
                padding: 15px 5px;
                border-bottom: 1px solid #eee;
                background-color: #232c39;
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: #fff !important;
                    margin: 0px;
                }
                .title {
                    text-align: center;
                    color: #fff;
                    font-size: 20px;
                    font-weight: bold;
                }
                .subtitle {
                    text-align: center;
                    color: #eee;
                    font-size: 7px;
                    
                    select {
                        color: #000;
                        height: 20px;
                        font-size: 12px;
                        width: 40px;
                    }
                }
            }
        }
        .textComponent {
            display: flex;
            align-items: center;
            flex-direction: column;
            .contentComponent {
                padding: 20px 0px;
                width: 100%;
                max-width: 720px;
                a, li {
                    color: #fff !important;
                }
            }
        }
        .completeContainer {
            .btnContainer {
                display: flex;
                align-items: center !important;
                justify-content: center !important;
                height: 80px !important;
                .button {
                    width: 200px !important;
                    height: 50px !important;
                    border-radius: 10px !important;
                    background: #8eb53e !important;
                    color: #fff !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-family: inherit;
                    font-size: 14px;
                }
            }
            .contentComponent {
                padding: 20px 0px;
                width: 100%;
                max-width: 720px;
                .result {
                    div {
                        color: #fff !important;
                    }
                }
                .btnContainer {
                    display: flex;
                    align-items: center !important;
                    justify-content: center !important;
                    height: 80px !important;
                    .button {
                        width: 200px !important;
                        height: 40px !important;
                        border-radius: 10px !important;
                        background: #8eb53e !important;
                        color: #fff !important;
                    }
                }
                a, li {
                    color: #fff !important;
                }
            }
        }
        .unitsComponent {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
            background: linear-gradient(to bottom, #232c39 95%, #8eb53e);
            justify-content: center;
            border-top: 1px solid white;
            .unitsConent {
                width: 100%;
                max-width: 720px;
                margin-bottom: 15px;
                .header {
                    padding: 15px 10px 15px 10px;
                    font-size: 20px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;

                    .triangle-down {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 15px solid #fff;
                    }
                    .triangle-up {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 15px solid #fff;
                    }
                }
                .unit {
                    padding: 0px 10px;
                    height: 59px;
                    display: flex;
                    border: 1px solid #888;
                    border-radius: 5px;
                    margin: 10px 0px;
                    align-items: center;
                    color: #fff;
                    background: linear-gradient(to bottom, #232c39 94%, #888);
                    cursor: not-allowed;
                    .text {
                        font-size: 15px;
                        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                    }
                    .dot {
                        width: 25px;
                        border-radius: 50%;
                        height: 25px;
                        min-height: 25px;
                        max-height: 25px;
                        min-width: 25px;
                        max-width: 25px;
                        border: 1px solid #8eb53e;
                        border: 1px solid #fff;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .active {
                        background: #fff;
                    }
                    .completed {
                        background: #8eb53e;
                    }

                    .selected {
                        border: 1px solid #8eb53e;
                        background: #eee;
                    }
                    .check {
                        color: #8eb53e !important;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    &.clickable {
                        cursor: pointer;
                    }
                    &.clickable {
                        cursor: pointer;
                    }
                }
                .footer {
                    padding: 15px 15px;
                    color: #fff;
                    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                    font-size: 15px;
                    text-align: right;
                    position: relative;
                    bottom: 5px;
                }
            }
        }
    }
}
@media screen and (max-width: 450px) {
    .root {
        width: 100%;
        overflow: auto;
        height: 100%;
        background-color: #232c39;

        .questionComponent,
        .textComponent,
        .videoComponent,
        .textComponent,
        .completeContainer {
            .header {
                width: 100%;
                border-bottom: 1px solid #eee;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #232c39;
                flex-direction: column;
                padding: 10px 5px;
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: #fff !important;
                    margin: 0px;
                }
                .title {
                    text-align: center;
                    color: #eee;
                    font-size: 15px;
                }

                .subtitle {
                    text-align: center;
                    color: #eee;
                    font-size: 7px;

                    select {
                        color: #000;
                        height: 20px;
                        font-size: 12px;
                        width: 40px;
                    }
                }
            }
        }
        .textComponent {
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;
            .contentComponent{
                color: #fff !important;
                width: 100%;
                a {
                    color: #fff !important;
                }
            }
        }
        .completeContainer {
            .contentHtml {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: #fff !important;
                }
                text-align: center;
            }
            .btnContainer {
                display: flex;
                align-items: center !important;
                justify-content: center !important;
                height: 80px !important;
                .button {
                    width: 200px !important;
                    height: 50px !important;
                    border-radius: 10px !important;
                    background: #8eb53e !important;
                    color: #fff !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .unitsComponent {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
            background: linear-gradient(to bottom, #232c39 95%, #8eb53e);
            justify-content: center;
            border-top: 1px solid white;
            .unitsConent {
                width: 100%;
                max-width: 720px;
                .header {
                    padding: 15px 10px 15px 10px;
                    font-size: 20px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .triangle-down {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 15px solid #fff;
                    }
                    .triangle-up {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 15px solid #fff;
                    }
                }
                .unit {
                    padding: 10px 10px;
                    border: 1px solid #fff;
                    border-radius: 5px;
                    margin: 15px 0px;
                    color: #fff;
                    cursor: not-allowed;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    display: flex;
                    align-items: center;
                    -o-user-select: none;
                    user-select: none;
                    // width: 95%;
                    transition: all 0.2s ease-in-out;
                    background: linear-gradient(to bottom, #232c39 94%, #888);
                    .text {
                        font-size: 15px;
                        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                    }
                    .dot {
                        width: 25px;
                        min-width: 25px;
                        max-width: 25px;
                        border-radius: 50%;
                        height: 25px;
                        max-height: 25px;
                        min-height: 25px;

                        border: 1px solid #8eb53e;
                        border: 1px solid #fff;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .active {
                        background: #fff;
                    }
                    .completed {
                        background: #8eb53e;
                    }

                    .selected {
                        border: 1px solid #8eb53e;
                        background: #eee;
                    }
                    .check {
                        color: #8eb53e !important;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    &.clickable {
                        cursor: pointer;
                    }
                    &.clickable {
                        cursor: pointer;
                    }
                }
                .footer {
                    padding: 15px 15px;
                    color: #fff;
                    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                    font-size: 15px;
                    text-align: right;
                    position: relative;
                    bottom: 5px;
                }
            }
        }
    }
}
