@media screen and (min-width: 449px) {
    .textContainer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        background-color: #fff;
        border:1px solid #fff;
        border-radius: 7px;
        text-align: center;
        padding: 10px 0px;
        .btns {
            position: relative;
            width: 100%;
            top: 0px;
            left: 0px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            // .btn {
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     color:#fff;
            //     background-color: #8eb53e;
            //     cursor: pointer;
            //     border-radius: 2px;
            //     width: 150px;
            //     height: 40px;
            //     z-index: 10;
            // }
        }
    }
}
@media screen and (max-width: 450px) {
    .textContainer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // background-color: #232c39;
        background-color: #fff;
        border:1px solid #fff;
        border-radius: 7px;
        text-align: center;
        padding: 10px 0px;
        .btns {
            position: relative;
            width: 100%;
            top: 0px;
            left: 0px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background-color: #8eb53e;
                cursor: pointer;
                border-radius: 2px;
                width: 150px;
                height: 40px;
                z-index: 10;
            }
        }
    }
}
