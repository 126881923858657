@media screen and (max-width:360px){
  .MypaContainer {
    color: white;
    .form-input {
      input {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width:530px){
  .MypaContainer {
    color: white;
    .form {
      display: inline-block !important;
      .divider {
        margin: 20px 0 !important;
        border-left: 0x !important;
        border-top: 1px #ccc solid !important;
      }
    }
  }
}


.MypaContainer {
  color: white;
  margin: auto;
  text-align: center;
  padding: 30px;
  .copyLink  {
    span {
      margin-right: 3px;
    }
    .hidden {
      display: none !important;
    }
    &:hover {
      span {        
        text-decoration: underline;
      }
      .hidden {
        display: inherit !important;
      }
      .shown {
        display: none;
      }
    }
  }

  .form {
    padding: 30px;
    border: 1px solid #ccc;    
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    .divider {
      margin: 0 20px;
      border-left: 1px #ccc solid;
    }
  }
  .form-input {
    text-align: left;
    margin: 10px 0;

    label {
      display:inline-block;
      min-width: 80px;
    }
    input {
      border: 1px solid #ccc;  
      border-radius: 5px;
      // width: 100%;
    }
    input:focus {
      border: 1.5px solid #8eb53e !important;
      outline-width: 0;
    }
  }
  .btn {
    display: inline;
  }

}

.error {
  color: red;
}
